import React from 'react';
import * as Sentry from '@sentry/browser';

export const ErrorFallback: React.FC = () => {
  return (
    <div className="fallback-error">
      <div>Something went wrong. Please try to reload</div>
      <a onClick={() => Sentry.showReportDialog()}>Report Feedback</a>
    </div>
  );
};
export default ErrorFallback;
