import { getParameterByName } from './utils/utils';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(process.env.REACT_APP_APP_TYPE);
    if (serializedState === null) {
      return undefined;
    }
    const persistedStore = JSON.parse(serializedState);
    if (persistedStore.partner !== process.env.REACT_APP_PARTNER) {
      return undefined;
    }
    const transactionCode = getParameterByName('transactionCode');
    const accessToken = getParameterByName('accessToken');
    const jobId = getParameterByName('jobId');
    const jobNumber = getParameterByName('jobNumber');
    if(transactionCode && persistedStore.home?.caseDetail?.case?.transactionToken !== transactionCode ){
      return undefined;
    }
    if(accessToken && persistedStore.vehicle?.serviceToken !== accessToken){
      return undefined;
    }
    return persistedStore;
  } catch (err) {
    return undefined;
  }
};

export const saveState = ({ home, vehicle }) => {
  try {
    const serializedState = JSON.stringify({
      home,
      vehicle,
      partner: process.env.REACT_APP_PARTNER,
    });
    localStorage.setItem(process.env.REACT_APP_APP_TYPE, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
