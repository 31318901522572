export default {
  Login: 'account-members/login',
  SignUp: 'account-members/',
  searchPolicy: {
    Signature: '/customers/auth',
    policy: '',

    set(policy) {
      this.policy = policy;
    },

    get get() {
      return this.Signature;
    },
  },

  getPolicyDetails: {
    Signature: '/policy?&policy=',
    policy: '',

    set(policy) {
      this.policy = policy;
    },

    get get() {
      return this.Signature + this.policy;
    },
  },

  sendVerificationCode: {
    Signature: '/customers/initiate-signup?phone=',
    phone: '',

    set(phone) {
      this.phone = phone;
    },

    get get() {
      return this.Signature + this.phone;
    },
  },
  verifyCode: {
    phone: '',
    code: '',
    get get() {
      return (
        '/customers/validate-signup?phone=' + this.phone + '&code=' + this.code
      );
    },
  },
  checkPhoneNumber: {
    Signature: '/customers/phone/check?phone=',
    phone: null,
    get get() {
      return this.Signature + this.phone;
    },
  },
  signinCode: {
    Signature: '/customers/signin-code?phone=',
    phone: null,
    get get() {
      return this.Signature + this.phone;
    },
  },
  signin: {
    Signature: '/customers/signin',
    get get() {
      return this.Signature;
    },
  },
  signupCode: {
    Signature: '/customers/signup-code?phone=',
    phone: null,
    get get() {
      return this.Signature + this.phone;
    },
  },
  signupValidate: {
    Signature: '/customers/signup?phone=',
    phone: null,
    code: null,
    get get() {
      return this.Signature + this.phone + '&code=' + this.code;
    },
  },
  refreshToken: {
    Signature: '/customers/auth/refresh',
    get get() {
      return this.Signature;
    },
  },
  validateToken: {
    Signature: '/customers/validate-by-token?token=',
    get get() {
      return this.Signature;
    },
  },
  customerSearch: {
    Signature: '/customers/search?offset=0&limit=20',
    get APIURL() {
      return this.Signature;
    },
  },
};
