export default {
  ACCESS_TOKEN: 'u9iPpKB4AArC7feZh1EEoB9zpxQz1Ixm',
  API_VERSION: process.env.REACT_APP_API_VERSION,
  INDUSTRY_TYPE: process.env.REACT_APP_INDUSTRY_TYPE,
  APP: process.env.REACT_APP_APP_TYPE,
  HOST_URL: process.env.REACT_APP_HOST_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  AUTH_CODE: '',
  ENVIROMENTUL:
    process.env.REACT_APP_BUILD == 'dev' || process.env.REACT_APP_BUILD == 'qa'
      ? 'dev'
      : 'prod',
  PARTNERCODE: process.env.REACT_APP_PARTNERCODE,
  POLICY_CLIENTID: process.env.REACT_APP_POLICY_CLIENTID,
  POLICY_CLIENTSECRET:process.env.REACT_APP_POLICY_CLIENTSECRET,
};
