// import {config as baseConfiguration} from '../../../configs/PubnubConfig';

// const loopbackAddress =
//   Platform.OS === 'ios'
//     ? baseConfiguration.host.ios
//     : baseConfiguration.host.android;

export default {
  port: 3000,
  host: '', //`http://${loopbackAddress}:${baseConfiguration.port}`,
  loopbackAddress: 'localhost',
  client: {
    publishKey: '',
    subscribeKey: 'Hello-Temp',
    ssl: false,
  },
  setConfigs(baseConfigs) {
    // console.log("Shared:Pubnub: setConfigs");
    this.client = {
      publishKey: '',
      subscribeKey: baseConfigs.pubnub.subscribeKey,
      ssl: false,
    };
    // console.log(this);
  },
};
