export const ConnectionState = {
  Idle: 'Idle',
  Connecting: 'Connecting',
  Connected: 'Connected',
  Failed: 'Failed',
  MessageReceived: 'Message',
};

export const DriverState = {
  None: 'None',
  CheckedIN: 'CheckedIN',
  CheckedOut: 'CheckedOut',
  Busy: 'Busy',
};

export const JobState = {
  // JOB_STATUS
  JOB_STATUS_NEW: 0,
  JOB_STATUS_FOUND_PROVIDERS: 1,
  JOB_STATUS_RE_DISPATCH: 1000,
  JOB_STATUS_CONTACTING_PROVIDERS: 2,
  JOB_STATUS_ASSIGNED: 3,
  JOB_STATUS_PROVIDER_ON_WAY: 4,
  JOB_STATUS_PROVIDER_ARRIVED: 5,
  JOB_STATUS_CC_AUTHORIZED: 6,
  JOB_STATUS_CC_VOIDED: 7,
  JOB_STATUS_PROVIDER_REACHING_SITE: 10,
  JOB_STATUS_PROVIDER_LEFT_SITE: 11,
  JOB_STATUS_NO_CC_ONLINE: 88,
  JOB_STATUS_PAYMENT_FAILED: 89,
  JOB_STATUS_ALREADY_COMPLETE: 90,
  JOB_STATUS_SYSTEM_ENTERED: 101,
  JOB_STATUS_POTENTIAL_ONLY: 200,
  JOB_STATUS_TEST_ONLY: 300,
  /* JOB expiry related status code */
  JOB_STATUS_EXPIRED: 24,
  JOB_STATUS_NOT_FOUND_PROVIDERS: 20,
  JOB_STATUS_COMPLETED_BY_PROVIDER_GOA: 25,
  JOB_STATUS_CLOSED_GOA_NO_PAYMENT: 93,
  JOB_STATUS_NO_CC_ON_FILE: 99,
  /* JOB cancel related status code. */
  JOB_STATUS_CANCELLED_BY_CSR: 28,
  JOB_STATUS_CANCELLED_BY_CUSTOMER: 21,
  JOB_STATUS_CANCELED_BY_PROVIDER: 9,
  JOB_STATUS_CANCELED_BY_PROVIDER_FOR_CUSTOMER: 22,
  JOB_STATUS_PROVIDER_CANCEL_BY_CONSUMER_REQUEST: 97,
  /* Receipt related status code */
  JOB_STATUS_COMPLETE_BY_PROVIDER: 8,
  JOB_STATUS_CLOSED_AS_EXPIRED: 91,
  JOB_STATUS_CLOSED_ALL_PROVIDER_REJECTED: 96,
  JOB_STATUS_CLOSED: 100,
  JOB_STATUS_CLOSED_PAID_BY_CASH: 92,
  JOB_STATUS_CLOSED_NO_PAYMENT_NEEDED: 98,
  JOB_STATUS_CLOSED_GOA: 99,
};

export const ProviderState = {
  // provider status codes */
  PROVIDER_CONTACTING: 0,
  PROVIDER_CONFIRMED: 1104,
  DRIVER_CONFIRMED: 1105,
  DRIVER_ON_THE_WAY: 1101,
  DRIVER_ARRIVED: 1102,
  DRIVER_ARRIVING: 1103,
  DRIVER_LEFT_ONSITE: 1210,
};

// export const JobState = {
//       no_KEY : 0, // New Job
//       create_JOB : 3001,
//       customer_CANCEL : 3002,
//       expire_JOB : 3003,
//       found_PROVIDER : 3004,
//       goa : 3005,
//       job_ASSIGNED : 3006,
//       provider_CANCEL : 3007,
//       provider_CANCEL_FOR_CUSTOMER : 3008,
//       job_COMPLETE : 3009,
//       out_OF_NETWORK : 3010,
//       job_UPDATE : 3011,
//       location_CHANGED : 3012,
//       drop_OFF_LOCATION_CHANGED : 3013,
//       cc_AUTHORIZED : 3014,
//       cc_VOID_AUTHORIZATION : 3015,
//       mark_CASH_PAY : 3016,
//       partner_PAYMENT_TOKEN_PROVIDED : 3017,
//       price_CHANGED : 3018,
//       goa_PRICE_CHANGED : 3019,
//       tow_MILES_CHANGED : 3020,
//       provider_ON_THE_WAY : 3021,
//       provider_ARRIVED : 3022,
//       assign_PROVIDER_COMPANY : 3023,
//       reopen_AUTO : 3024,
//       reopen_AND_ASSIGN : 3025,
//       apply_PROMO : 3026,
//       close_AS_POTENTIAL : "3027",
//       escalate_JOB : 3028,
//       close_AS_TEST : 3029,
//       update_CUSTOMER_NAME : 3030,
//       close_FOR_USER : 3031,
//       assign_USER : 3032,
//       rename_JOB : 3033,
//       personnel_LOCATION_UPDATE : 3034,
//       payment_INSTRUMENT_VALID : 3035,
//       demo_JOB : 3036,
//       service_TYPE_CHANGE : 3037,
//       job_NOT_ASSIGNED : 3040,
//       eta_CHANGED : 3041,
//       alert : 3042,
//       alert_CLOSE : 3043,
//       provider_ACCEPT : 3044,
//       provider_REJECT : 3045,
//       alert_ACKED : 3046,
//       driver_IN_APP_MESSAGE : 3047,
//       provider_WAKEUP : 3049,
// };
