import { combineReducers } from 'redux';

//import vehicle from "./Vehicle/reducer";
import vehicle from '../customer-vehicle-determination/src/reducers/Vehicle/reducer';
import home from '../customer-job-flow/src/reducers/Home/reducer';
import { connectionReducer } from '../customer-job-flow/src/shared/Pubnub/reducers/Connection';

const appReducer = combineReducers({
  vehicle,
  home,
  connection: connectionReducer,
});

export default function rootReducer(state, action) {
  return appReducer(state, action);
}
