import { connect, disconnect } from '../pubnub';

export const CONNECTING = 'CONNECT';
export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const MESSAGERECEIVED = 'MESSAGERECEIVED';

export const connectionActions = {
  connect() {
    // console.log("Start - connect");
    return (dispatch) => {
      dispatch({ type: CONNECTING });

      return connect()
        .then(({ uuid }) => {
          dispatch({ type: CONNECTED, payload: uuid });
        })
        .catch((error) => {
          dispatch({ type: DISCONNECTED, payload: { error } });

          // Attempt to reconnect on a timer
          const reconnect = () => connectionActions.connect()(dispatch);

          setTimeout(reconnect, 1500);
        });
    };
  },

  disconnect() {
    // console.log("Start - disconnect ");
    return (dispatch) => {
      disconnect().then(() => dispatch({ type: DISCONNECTED, payload: {} }));
    };
  },

  receiveMessage(message) {
    // console.log("receiveMessage");
    return (dispatch) => {
      disconnect().then(() =>
        dispatch({ type: MESSAGERECEIVED, payload: { message } })
      );
    };
  },

  failure(error) {
    return { type: DISCONNECTED, payload: { error } };
  },
};
