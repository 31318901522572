import { createStore, applyMiddleware } from 'redux';

import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
//import promise from "redux-promise";

import * as reducer from '../reducers';
export default function configureStore(initialState, isActual = true) {
  if (
    (process.env.REACT_APP_BUILD == 'dev' ||
      process.env.REACT_APP_BUILD == 'qa') &&
    isActual === true
  ) {
    const logger = createLogger();
    const middleWare = applyMiddleware(thunk, logger);
    return createStore(reducer.default, initialState, middleWare);
  } else {
    //TODO: Need to remove logger from production build which will increase performance in build.
    const middleWare = applyMiddleware(thunk);
    return createStore(reducer.default, initialState, middleWare);
  }
}
